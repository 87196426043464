import i18n from 'i18n-literally'
import {
  filter,
  groupBy,
  map,
  pipe,
  prop,
  reduce,
} from 'ramda'
import { createSelector } from 'redux-bundler'

import { titleize } from 'inflection'
import { normalize } from 'normalizr'
import reduceReducers from 'reduce-reducers'

import { dataTypeSort, isSubstrate } from '~/src/DataType/utils'
import createEntityBundle, {
  doEntitiesReceived,
  doEntitiesRemoved,
  getAsyncActionIdentifiers,
} from '~/src/Lib/createEntityBundle'
import {
  EMPTY_OBJECT,
  formattedDate,
  getDateTime,
  parseApiErrors,
  shallowEquals,
  shallowEqualsMemoizer,
} from '~/src/Lib/Utils'
import { markAnnotationsOutdatedWrapper } from '~/src/Store/bundles/chart'
import { TargetRange as schema } from '~/src/Store/Schemas'
import DataTypeName from '~/src/UI/Shared/DataTypeName'
import HarvestWidget from '~/src/UI/Widgets/V2/Harvest'

import { dataPicker, nightFields, rangePicker } from '../utils'
import { schemaName as name } from './constants'

/**
 * Filter target ranges by currently active then group by room
 * @type {function}
 * @param {object} targetRanges Entity store slice for target and alert ranges
 * @returns {object} Active ranges by room (roomId => range[])
 */
const activeRangesByRoom = shallowEqualsMemoizer(pipe(
  Object.values, // Convert id => range mapping object to an array
  filter(range => {
    const today = (new Date()).toISOString().split('T').shift()
    return range.startDate <= today && (!range.endDate || range.endDate >= today) && !range.phaseId
  }),
  groupBy(prop('room'))
), { depth: 2 })

const fetchList = getAsyncActionIdentifiers('fetch_list', name)

const writesConfig = { wrapper: markAnnotationsOutdatedWrapper }

const formatSnackbarMessage = ({ action: mainAction, dataType, meta = EMPTY_OBJECT, status, payload, response }) => {
  const { action: metaAction } = meta
  const action = metaAction || mainAction
  const message = (
    <>
      {status === 'fail' ? i18n`${titleize(action)} failed for` : i18n`${titleize(action)}d`}
      {' '}
      <DataTypeName dataType={dataType} icon />
      {'\u00a0'}
      {[payload.alertMin, payload.alertMax].every(v => v != null && v !== '')
        ? i18n`targets & alerts`
        : i18n`target range`}
      {action === 'remove' ? i18n` effective ${formattedDate(getDateTime(response.endDate).endOf('day'), 'SHORT_TIME')} yesterday` : null}
      {payload.phaseId ? (
        <>
          {i18n` in `}
          <div style={{ maxWidth: 400 }}>  {/* Max width of snackbar message */}
            <HarvestWidget phase={payload.phaseId} />
          </div>
        </>
      ) : null}
    </>
  )
  let level = status === 'fail' ? 'error' : 'success'
  if (action === 'delete' && level === 'success') {
    level = 'info'
  }
  return [message, level]
}

const bundle = createEntityBundle({
  name,
  apiConfig: {
    prepareData: data => {
      const { startDate, endDate } = data

      return {
        ...data,
        startDate: startDate ? getDateTime(startDate).toISODate() : null,
        endDate: endDate ? getDateTime(endDate).toISODate() : null
      }
    },
    delete: writesConfig,
    save: writesConfig,
    schema,
    snackbar: ({ action, meta, payload, status, store, ...rest }) => {
      if (
        (action === 'fetch' && status !== 'fail')
        || (action === 'save' && status === 'fail')
        || (meta && meta.snackbar === false)
      ) return
      const dataTypes = store.selectDataTypes()
      const { [payload.dataTypeKey]: dataType } = dataTypes
      const [message, level] = formatSnackbarMessage({
        ...rest,
        action,
        dataType,
        meta,
        payload
      })
      store.doAddSnackbarMessage(message, level)
    }
  },
  parents: { phases: 'phaseId' }
})

const defaultState = {
  inflight: {},
  listErrors: {},
}

const isHistoricalAndActive = ({ startDate, endDate }, now) => {
  const yesterday = now.minus({ days: 1 })
  return getDateTime(startDate) <= yesterday.startOf('day')
    && (!endDate || getDateTime(endDate).endOf('day') > yesterday.endOf('day'))
}

export default {
  ...bundle,
  reducer: reduceReducers(defaultState, bundle.reducer, (state, action) => {
    if (!action.type.startsWith(fetchList.types.prefix)) return state
    switch (action.type) {
      case fetchList.types.start: {
        return {
          ...state,
          inflight: {
            ...state.inflight,
            [action.payload.room]: true,
          },
        }
      }

      case fetchList.types.fail: {
        return {
          ...state,
          inflight: {
            ...state.inflight,
            [action.payload.room]: false,
          },
          listErrors: {
            ...state.listErrors,
            [action.payload.room]: {
              error: action.error,
              params: action.payload,
            },
          },
        }
      }

      case fetchList.types.succeed: {
        const { room, ...payloadRest } = action.payload
        return {
          ...state,
          inflight: {
            ...state.inflight,
            [room]: false,
          },
          listErrors: {
            ...state.listErrors,
            [room]: undefined,
          },
          active: {
            ...state.active,
            [room]: { ...payloadRest }
          }
        }
      }

      default:
        return state
    }
  }),
  doTargetRangeSet: targetRange => ({ store }) => {
    const { id, phaseId, room } = targetRange
    const useNightDay = nightFields.some(field => targetRange[field] != null)
    const now = getDateTime('now')
    const isNewRoomRange = Boolean(!id && room && !phaseId)
    const isExistingRoomRange = Boolean(id && room && !phaseId) && isHistoricalAndActive(targetRange, now)
    const { [id]: previousRange } = store.selectTargetRanges()
    const rangeChanged = previousRange && !shallowEquals(
      rangePicker(previousRange),
      rangePicker(targetRange)
    )
    if (isNewRoomRange) {
      return store.doTargetRangeSave({ ...targetRange, startDate: now.toISODate(), useNightDay })
    }
    if (isExistingRoomRange && rangeChanged) {
      // If we are "updating" a room target range that started before today, we need to create a new one
      return store.doTargetRangeSave({
        ...previousRange,
        endDate: now.minus({ days: 1 }).toISODate(),
      }, { snackbar: false }).then(() => {
        store.dispatch(doEntitiesRemoved({ targetRanges: [previousRange.id] }))
        return store.doTargetRangeSave({
          ...rangePicker(targetRange),
          ...dataPicker(targetRange),
          startDate: now.toISODate(),
          useNightDay,
        })
      })
    }
    return store.doTargetRangeSave({ ...targetRange, useNightDay })
  },
  doTargetRangeRemove: targetRange => ({ store }) => {
    const { id, phaseId, room } = targetRange
    const now = getDateTime('now')
    const isExistingRoomRange = Boolean(id && room && !phaseId) && isHistoricalAndActive(targetRange, now)
    const yesterday = now.minus({ days: 1 }).toISODate()
    let response
    // room target ranges that have been active
    if (isExistingRoomRange) {
      response = store.doTargetRangeSave({ ...targetRange, endDate: yesterday }, { action: 'remove' })
      store.dispatch(doEntitiesRemoved({ targetRanges: [id] }))
      return response
    }
    response = store.doTargetRangeDelete(targetRange)
    return response
  },
  doTargetRangesFetch: payload => async ({ apiFetch, dispatch }) => {
    const { active } = payload

    dispatch({ type: fetchList.types.start, payload })

    try {
      const response = await apiFetch('/targetRanges/', payload)

      const { entities, result: list } = normalize(response, [schema])

      dispatch(doEntitiesReceived(entities))

      dispatch({ type: fetchList.types.succeed, payload: { ...payload, list, date: active } })

      return response
    } catch (error) {
      dispatch({ type: fetchList.types.fail, error, payload })
      return null
    }
  },
  doTargetRangesSave: (payload, meta) => markAnnotationsOutdatedWrapper(async ({ store }) => {
    const { targetRanges, startDate, endDate, phaseId, room } = payload
    if (!targetRanges) return false

    const dataTypes = store.selectDataTypes()
    try {
      const results = await Promise.allSettled(
        Object.entries(targetRanges).map(([key, range]) => {
          const {
            [key]: { id: dataType },
          } = dataTypes
          const apiPayload = {
            ...range,
            phaseId,
            room,
            dataType,
            startDate: startDate ?? range.startDate,
            endDate: endDate ?? range.endDate,
          }
          return store.doTargetRangeSet(apiPayload)
        })
      )
      if (meta.returnAll) return results
      const [result] = results
      return result
    } catch (error) {
      return false
    }
  }),
  doOutdoorTargetRangesSave: payload => markAnnotationsOutdatedWrapper(async ({ store, apiFetch }) => {
    try {
      const result = await apiFetch('/targetRanges/bulk/', payload, { method: 'POST' })
      store.doAddSnackbarMessage('Target ranges have been saved')
      return result
    } catch (error) {
      store.doAddSnackbarMessage(parseApiErrors(error.response) ?? 'Failed')
      return false
    }
  }),
  doTargetRangesDelete: payload => markAnnotationsOutdatedWrapper(async ({ store }) => {
    try {
      const result = await Promise.all(
        payload.filter(r => r.id).map(r => store.doTargetRangeRemove(r))
      )
      return result
    } catch (error) {
      return false
    }
  }),
  selectTargetRangesByRoomAndType: createSelector(
    'selectTargetRanges',
    pipe(
      activeRangesByRoom,
      map(roomRanges => roomRanges.reduce((acc, range) => {
        if (range.phaseId) return acc
        const { [range.dataTypeKey]: previous } = acc
        if (previous) {
          const rangeEndsFirst = previous.endDate
            && range.endDate
            && previous.endDate > range.endDate
          const prevUnendedRangeEnded = !previous.endDate && range.endDate
          if (rangeEndsFirst || prevUnendedRangeEnded) {
            return acc
          }
        }
        return {
          ...acc,
          [range.dataTypeKey]: range,
        }
      }, {}))
    )
  ),
  selectPhaseTargetRangesByRoomAndType: createSelector(
    'selectTargetRanges',
    pipe(
      activeRangesByRoom,
      map(roomRanges => roomRanges.reduce((acc, range) => {
        if (!range.phaseId) {
          return acc
        }
        const { [range.dataTypeKey]: previous } = acc
        if (previous) {
          const rangeEndsFirst = previous.endDate
            && range.endDate
            && previous.endDate > range.endDate
          const prevUnendedRangeEnded = !previous.endDate && range.endDate
          if (rangeEndsFirst || prevUnendedRangeEnded) {
            return acc
          }
        }
        return {
          ...acc,
          [range.dataTypeKey]: range,
        }
      }, {}))
    )
  ),
  selectTargetRangesByPhaseAndType: createSelector(
    'selectTargetRanges',
    'selectRecipes',
    (targetRanges = EMPTY_OBJECT, recipes = EMPTY_OBJECT) => {
      const allTargets = Object.values(recipes).reduce((trs, recipe) => {
        if (recipe.phases.some(p => p.targetRanges.length)) {
          recipe.phases.forEach(p => {
            if (!p.targetRanges.length) {
              return
            }
            trs.push(...p.targetRanges)
          })
        }
        return trs
      }, Object.values(targetRanges))

      return allTargets.reduce((acc, range) => {
        if (!range.phaseId) {
          return acc
        }

        const { phaseId, dataTypeKey } = range
        const { [phaseId]: phase = {} } = acc
        phase[dataTypeKey] = range
        acc[phaseId] = phase

        return acc
      }, {})
    }
  ),
  selectTargetRangeErrorsByType: createSelector(
    'selectTargetRangesDirty',
    //
    pipe(
      Object.values,
      filter(prop('lastError')),
      reduce((acc, entry) => {
        const {
          lastError: {
            error,
            requestPayload: { dataTypeKey },
          },
        } = entry
        return { ...acc, [dataTypeKey]: error }
      }, EMPTY_OBJECT)
    )
  ),
  selectTargetRangeDataTypes: createSelector(
    // TODO: verify with product H421 Climate ONE
    'selectFacilityActiveDataTypes',
    activeDataTypes => Object.values(activeDataTypes ?? EMPTY_OBJECT).filter(dt => !dt.hidden && dt.hasTargets)
  ),
  selectTargetRangeSoilDataTypes: createSelector(
    // TODO: a backend will show a property like has targets to filter in our data to only show the ones that have it
    'selectFacilityActiveDataTypes',
    activeDataTypes => dataTypeSort(Object.values(activeDataTypes ?? EMPTY_OBJECT)
      .filter(dt => !dt.hidden && isSubstrate(dt) && dt.hasTargets))
  ),
  selectTargetRangeEnvDataTypes: createSelector(
    'selectFacilityActiveDataTypes',
    activeDataTypes => dataTypeSort(Object.values(activeDataTypes ?? EMPTY_OBJECT)
      .filter(dt => !dt.hidden && !isSubstrate(dt) && dt.hasTargets))
  ),
  selectRoomActiveTargets: createSelector(
    'selectTargetRangesRoot',
    'selectTargetRanges',
    'selectCurrentRoomId',
    ({ active = EMPTY_OBJECT }, targetRanges, roomId) => {
      const { [roomId]: roomActive } = active
      if (!roomActive) return EMPTY_OBJECT
      return groupBy(
        prop('dataTypeKey'),
        roomActive.list.map(targetId => targetRanges[targetId])
      )
    }
  )
}
