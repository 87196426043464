import i18n from 'i18n-literally'
import { curry } from 'ramda'
import { createSelector } from 'redux-bundler'

import {
  BarChart as FacilityPerfIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  ShowChart as RoomDashboardIcon,
} from '@mui/icons-material'

import { fallbackContent } from '~/src/App/utils'
import { urls as harvestUrls } from '~/src/Harvest/bundle'
import { getInventoryPermits } from '~/src/Inventory/utils'
import createRouteBundle from '~/src/Lib/createRouteBundle'
import { EMPTY_ARRAY, lazyWithRetries } from '~/src/Lib/Utils'
import { urls as recipeUrls } from '~/src/Recipe/bundle'
import { urls as roomUrls } from '~/src/Room/bundle'
import Forgot from '~/src/Routes/Forgot'
import Reset from '~/src/Routes/Forgot/reset'
import Login from '~/src/Routes/Login'
import { URL_ACTION } from '~/src/Store/constants'
import BatchIcon from '~/src/UI/Icons/Batch'
import JournalIcon from '~/src/UI/Icons/JournalNav'
import MetrcIcon from '~/src/UI/Icons/Metrc'
import PestIcon from '~/src/UI/Icons/Pest'
import Strain from '~/src/UI/Icons/Strain'
import StrainrunsIcon from '~/src/UI/Icons/Strainruns'
import RedirectTo from '~/src/UI/Shared/RedirectTo'

import {
  FACILITY_REDIRECT,
  FACILITY_ROUTE_PREFIX,
  FACILITY_SEGMENT_PATTERN,
  PUBLIC_ROUTES,
  REGISTER_ROUTES,
} from './constants'
import Logout from './Logout'
import RedirectToFacilityPrefixed from './RedirectToFacilityPrefixed'
import FlagAccountCreationWrapper from './Register/Flag/FlagWrapper'

const AccountCreationRegisterGateway = lazyWithRetries(() => import('~/src/Routes/Register'))
const AccountCreationSuccess = lazyWithRetries(() => import('~/src/Routes/Register/AccountFlow/AccountCreationSuccess'))
const AccountCreationFacilityInfo = lazyWithRetries(() => import('~/src/Routes/Register/AccountFlow/FacilityInfo'))
const AccountCreationPassword = lazyWithRetries(() => import('~/src/Routes/Register/AccountFlow/Password'))
const AccountCreationPersonalData = lazyWithRetries(() => import('~/src/Routes/Register/AccountFlow/PersonalData'))
const AccountCreationGatewaySuccess = lazyWithRetries(() => import('~/src/Routes/Register/GatewaySuccess'))
const AccountCreationError = lazyWithRetries(() => import('~/src/Routes/Register/AccountFlow/AccountCreationError'))
const AccountProfile = lazyWithRetries(() => import('~/src/Account/Profile'))
const Account = lazyWithRetries(() => import('~/src/Account/Management'))
const ComplianceInbox = lazyWithRetries(() => import('~/src/Compliance/Routes'))
const Cultivars = lazyWithRetries(() => import('~/src/Cultivars'))
const CultivarSpecification = lazyWithRetries(() => import('~/src/Cultivars/Specification'))
const DevicesQR = lazyWithRetries(() => import('~/src/Device/qr'))
const FacilityDashboard = lazyWithRetries(() => import('~/src/Facility/Routes/Dashboard'))
const HarvestDetail = lazyWithRetries(() => import('~/src/Harvest/Detail'))
const HarvestCreate = lazyWithRetries(() => import('~/src/Harvest/Routes/Create'))
const IPMList = lazyWithRetries(() => import('~/src/IPM/Routes'))
const IPMPesticideList = lazyWithRetries(() => import('~/src/IPM/Routes/PesticideList'))
const AllEventsJournal = lazyWithRetries(() => import('~/src/Journal/Routes/AllEventsJournal'))
const FacilityPerformance = lazyWithRetries(() => import('~/src/Performance'))
const Production = lazyWithRetries(() => import('~/src/Production'))
const RecipeDetail = lazyWithRetries(() => import('~/src/Recipe/Detail'))
const RoomInventory = lazyWithRetries(() => import('~/src/Room/Inventory'))
const RoomDashboard = lazyWithRetries(() => import('~/src/Room/Routes/Dashboard'))
const RoomDashboardRedirect = lazyWithRetries(() => import('~/src/Room/Routes/Dashboard').then(
  ({ RedirectRoute }) => ({ default: RedirectRoute })
))
const NotFound = lazyWithRetries(() => import('~/src/Routes/NotFound'))
const Setup = lazyWithRetries(() => import('~/src/Setup'))
const StaffManagement = lazyWithRetries(() => import('~/src/Staff/Routes/Manage'))
const StrainrunAnalytics = lazyWithRetries(() => import('~/src/Strainrun/Analytics'))

export const roomRoute = '/rooms/:id'
export const inventoryRoute = '/inventory'

const PRIVATE_ROUTE_REDIRECT = {
  component: RedirectToFacilityPrefixed,
  [FACILITY_REDIRECT]: true,
}

const ROOM_DASHBOARD_ROUTE_CONFIG = {
  component: RoomDashboard,
  actions: 'view_room',
}

const GROWLOG_ROUTE_CONFIG = {
  component: HarvestDetail,
  title: i18n`Harvest Growlog`,
  actions: ['view_harvest'],
  negate: 'tiers',
  tiers: 'GO',
}

const CULTIVARS_ROUTE_CONFIG = {
  component: Cultivars,
  features: ['CULTIVAR_PROFILES'],
  actions: 'view_cultivar_profiles',
  title: i18n`Cultivars`,
  negate: 'tiers',
  tiers: 'GO',
}

const CULTIVAR_PROFILE_ROUTE_CONFIG = {
  component: CultivarSpecification,
  actions: 'view_cultivar_profiles',
  negate: 'tiers',
  tiers: 'GO',
  title: i18n`Cultivar Profile`,
  PermissionFallback: NotFound,
}

const PROFILE_ROUTE_CONFIG = {
  component: AccountProfile,
  title: i18n`Your Profile`,
}

const routeBundle = createRouteBundle(Object.entries({
  // This route is a special case that will be used to redirect to the dashboard or login page
  '/': {
    component: () => fallbackContent,
    publicAccess: true,
    viewMode: 'fullscreen',
  },
  '/dashboard': {
    component: FacilityDashboard,
    title: i18n`Facility Dashboard`,
    nav: {
      Icon: DashboardIcon,
      sequence: 1,
    },
  },
  [PUBLIC_ROUTES.LOGIN]: {
    component: Login,
    publicAccess: true,
    title: i18n`Login`,
    viewMode: 'fullscreen',
  },
  [PUBLIC_ROUTES.LOGOUT]: Logout,
  [PUBLIC_ROUTES.FORGOT]: {
    component: Forgot,
    publicAccess: true,
    title: i18n`Forgot Password?`,
    viewMode: 'fullscreen',
  },
  [`${PUBLIC_ROUTES.FORGOT}/:email`]: {
    component: Forgot,
    publicAccess: true,
    title: i18n`Forgot Password?`,
    viewMode: 'fullscreen',
  },
  [`${PUBLIC_ROUTES.RESET}/:token`]: {
    component: Reset,
    publicAccess: true,
    viewMode: 'fullscreen',
  },
  [PUBLIC_ROUTES.SWITCHING]: {
    additionalProps: {
      switching: true,
    },
    component: Login,
    publicAccess: true,
    title: i18n`Switching facilties`,
    viewMode: 'fullscreen',
  },
  [`${REGISTER_ROUTES.GATEWAY}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationRegisterGateway,
      requiredToken: false,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`Register gateway`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.GATEWAY_SUCCESS}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationGatewaySuccess,
      requiredToken: true,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`Success`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.NEW_ACCOUNT}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationPersonalData,
      requiredToken: true,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`New Account`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.FACILITY_INFO}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationFacilityInfo,
      requiredToken: true,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`New Account`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.PASSWORD}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationPassword,
      requiredToken: true,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`New Account`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.ACCOUNT_SUCCESS}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationSuccess,
      requiredToken: true,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`Success`,
    setting: ['ACCOUNT_CREATION'],
  },
  [`${REGISTER_ROUTES.ACCOUNT_ERROR}`]: {
    component: FlagAccountCreationWrapper,
    additionalProps: {
      handler: AccountCreationError,
      requiredToken: false,
      setting: 'ACCOUNT_CREATION'
    },
    publicAccess: true,
    title: i18n`Success`,
    setting: ['ACCOUNT_CREATION'],
  },
  '/setup': {
    component: Setup,
    title: i18n`Setup`,
    actions: ['change_facility', 'change_staff', 'view_device', 'change_compliance'],
    anyAction: true,
    nav: {
      Icon: SettingsIcon,
      sequence: 10,
    }
  },
  '/setup/inventory': {
    component: Setup,
    title: i18n`Setup - Inventory`,
    actions: 'change_compliance',
  },
  '/setup/devices': {
    component: Setup,
    title: i18n`Setup - Devices`,
    actions: 'view_device',
  },
  '/setup/controllers': {
    component: Setup,
    title: i18n`Setup - Irrigation Controllers`,
    actions: 'view_irrigation',
  },
  '/setup/facility': {
    component: Setup,
    title: i18n`Setup - Facility`,
    actions: 'change_facility',
  },
  '/setup/staff': {
    component: Setup,
    title: i18n`Setup - Staff`,
    actions: 'change_staff',
  },
  '/rooms': {
    component: RoomDashboardRedirect,
    actions: 'view_room',
    nav: {
      Icon: RoomDashboardIcon,
      label: ({ outdoor }) => i18n`${outdoor ? i18n`Field` : i18n`Room`} Dashboard`,
      sequence: 2,
      getURL: ({ currentFacilityId, rooms = [] }) => {
        if (currentFacilityId && rooms.length) {
          const [id] = rooms
          return `/f/${currentFacilityId}${roomUrls.getUrl({ id })}`
        }
        return roomUrls.list
      },
      getUrlList: ({ currentFacilityId, rooms = [] }) => {
        if (currentFacilityId && rooms.length) {
          return rooms.flatMap(id => [
            `/f/${currentFacilityId}${roomUrls.getUrl({ id })}`,
            `/f/${currentFacilityId}${roomUrls.getUrl({ id, action: 'dashboard' })}`,
          ])
        }
        return EMPTY_ARRAY
      }
    }
  },
  '/rooms/:id/inventory': {
    component: RoomInventory,
    actions: ['view_room', getInventoryPermits],
  },
  '/rooms/:id': ROOM_DASHBOARD_ROUTE_CONFIG,
  '/rooms/:id/dashboard': ROOM_DASHBOARD_ROUTE_CONFIG,
  // '/r/:id/:slug': RoomDashboard,
  '/profile': {
    ...PROFILE_ROUTE_CONFIG,
    urlTest: url => url.match(/^(\/f\/d+)?\/profile/)
  },
  '/profile/:tab': PROFILE_ROUTE_CONFIG,
  '/account': {
    component: Account,
    title: i18n`Your Account`,
    // actions: 'manage_account',
    features: 'CRM_INTEGRATION',
    Fallback: AccountProfile,
    PermissionFallback: NotFound
  },
  '/journal': {
    component: AllEventsJournal,
    title: i18n`Journal`,
    nav: {
      Icon: JournalIcon,
      sequence: 4,
    }
  },
  '/qr/:sn': {
    component: DevicesQR,
  },
  '/staff/manage': {
    component: StaffManagement,
    title: i18n`Manage Staff`,
    actions: 'change_staff',
    negate: 'tiers',
    tiers: 'GO',
  },
  '/staff/:id': {
    component: AccountProfile,
    actions: 'change_staff',
  },
  '/ipm/pesticides': {
    component: IPMPesticideList,
    title: i18n`IPM Pesticides`,
    actions: 'change_pesticide_application',
    negate: 'tiers',
    tiers: 'GO',
  },
  '/ipm': {
    component: IPMList,
    title: i18n`IPM Events`,
    actions: 'view_pesticide_application',
    negate: 'tiers',
    tiers: 'GO',
    nav: {
      Icon: PestIcon,
      sequence: 5,
    }
  },
  '/production': {
    component: Production,
    title: i18n`Production`,
    actions: ['view_harvest', 'view_recipe', 'view_package', 'view_plant'],
    anyAction: true,
    negate: 'tiers',
    tiers: 'GO',
    nav: {
      Icon: BatchIcon,
      sequence: 6,
      getUrlList: ({ currentFacilityId }) => [
        '/harvests',
        '/recipes',
        '/inventory',
        '/irrigationTemplates',
      ].map(pattern => `/f/${currentFacilityId}${pattern}`),
      negate: 'tiers',
      tiers: 'GO',
    }
  },
  '/irrigationSchedules': {
    component: RedirectTo,
    additionalProps: { url: '/irrigationTemplates' }
  },
  '/irrigationTemplates': {
    component: Production,
    title: i18n`Irrigation schedules`,
    actions: ['view_irrigation'],
  },
  [inventoryRoute]: {
    component: Production,
    title: i18n`Inventory`,
    actions: [getInventoryPermits],
    features: ['METRC'],
  },
  [recipeUrls.list]: {
    component: Production,
    title: i18n`Recipes`,
    actions: 'view_recipe'
  },
  [recipeUrls.create]: {
    component: RecipeDetail,
    title: i18n`Create Recipe`,
    actions: 'change_recipe',
  },
  [recipeUrls.view]: {
    component: RecipeDetail,
    actions: 'view_recipe',
  },
  [harvestUrls.list]: {
    component: Production,
    title: i18n`Harvests`,
    actions: 'view_harvest',
    negate: 'tiers',
    tiers: 'GO'
  },
  [harvestUrls.cultivars]: {
    component: HarvestDetail,
    title: i18n`Cultivars`,
    permissions: 'view_harvest',
  },
  [harvestUrls.create]: {
    component: HarvestCreate,
    title: i18n`Create Harvest`,
    actions: 'change_harvest',
    negate: 'tiers',
    tiers: 'GO'
  },
  [harvestUrls.view]: GROWLOG_ROUTE_CONFIG,
  [harvestUrls.compare]: {
    component: HarvestDetail,
    title: i18n`Run Comparison`,
    actions: ['view_harvest'],
    features: ['RUN_COMPARISON'],
    negate: 'tiers',
    tiers: 'GO',
  },
  [harvestUrls.schedule]: {
    component: HarvestDetail,
    title: i18n`Harvest Schedule`,
    actions: 'view_recipe',
    negate: 'tiers',
    tiers: 'GO',
  },
  [harvestUrls.yield]: {
    component: HarvestDetail,
    title: i18n`Harvest Yield`,
    actions: 'view_harvest',
    features: ['METRC'],
    negate: ['features', 'tiers'],
    tiers: 'GO',
  },
  [harvestUrls.growlog]: GROWLOG_ROUTE_CONFIG,
  [harvestUrls.analytics]: {
    component: HarvestDetail,
    title: i18n`Harvest Analytics`,
    actions: 'view_harvest',
    negate: 'tiers',
    tiers: 'GO',
  },
  [harvestUrls.inventory]: {
    component: HarvestDetail,
    title: i18n`Harvest Inventory`,
    actions: ['view_harvest', getInventoryPermits],
    negate: 'tiers',
    tiers: 'GO',
  },
  '/runs': {
    title: i18n`Runs`,
    component: StrainrunAnalytics,
    features: 'STRAINRUN_ANALYTICS',
    negate: 'tiers',
    tiers: 'GO',
    nav: {
      Icon: StrainrunsIcon,
      sequence: 7,
    }
  },
  '/facility/performance': {
    component: FacilityPerformance,
    title: i18n`Facility Performance`,
    actions: 'view_performance',
    features: 'FACILITY_PERFORMANCE',
    negate: 'tiers',
    tiers: 'GO',
    nav: {
      Icon: FacilityPerfIcon,
      sequence: 9,
    }
  },
  '/cultivars': {
    ...CULTIVARS_ROUTE_CONFIG,
    nav: {
      Icon: Strain,
      sequence: 8,
    },
    urlTest: url => url.match(/(\/f\/d+)?\/cultivars/)
  },
  '/cultivars/performance': CULTIVARS_ROUTE_CONFIG,
  '/cultivars/:id': CULTIVAR_PROFILE_ROUTE_CONFIG,
  '/cultivars/:id/:view': CULTIVAR_PROFILE_ROUTE_CONFIG,
  '/cultivars/:id/:view/:tab': CULTIVAR_PROFILE_ROUTE_CONFIG,
  '/compliance': {
    component: ComplianceInbox,
    actions: ['sync_cultivation', 'sync_processing', 'view_inbox'],
    additionalProps: { inbox: true },
    anyAction: true,
    features: ['METRC'],
    title: i18n`Compliance Inbox`,
    nav: {
      Icon: MetrcIcon,
      sequence: 3,
      getUrlList: ({ currentFacilityId }) => [
        '/compliance',
        '/compliance/history',
        '/compliance/tags'
      ].map(pattern => (
        `/f/${currentFacilityId}${pattern}`
      )),
    }
  },
  '/compliance/history': {
    component: ComplianceInbox,
    actions: ['sync_cultivation', 'sync_processing', 'view_inbox'],
    additionalProps: { history: true },
    anyAction: true,
    features: ['METRC'],
  },
  '/compliance/tags': {
    component: ComplianceInbox,
    actions: ['sync_cultivation', 'sync_processing', 'view_inbox'],
    additionalProps: { tags: true },
    anyAction: true,
    features: ['METRC'],
  },
  // This route cannot be part of PUBLIC_ROUTES because PUBLIC_ROUTES must be able to match
  // a finite set of routes, but this pattern matches all routes
  '*': {
    component: NotFound,
    publicAccess: true,
    is404: true,
    title: i18n`Unable to locate resource`,
  },
}).reduce((routes, [path, config]) => {
  if (config.publicAccess) {
    routes[path] = config
  } else {
    routes[path] = PRIVATE_ROUTE_REDIRECT
    routes[`${FACILITY_ROUTE_PREFIX}${path}`] = config
  }
  return routes
}, {}))

routeBundle.getMiddleware = () => curry((store, next, action) => {
  if (action.type !== URL_ACTION) {
    return next(action)
  }
  const { url, ...payload } = action.payload
  const path = url.replace(location.origin, '')
  const { facility } = store.selectRouteParams()
  const routeInfo = store.selectRouteMatcher()(path)
  const { params: nextParams, pattern, value: route } = routeInfo
  if (pattern && pattern.startsWith(FACILITY_ROUTE_PREFIX)) {
    if (nextParams.facility && facility !== nextParams.facility) {
      return store.doFacilitySwitch({
        facility: Number(nextParams.facility),
        url: path,
      })
    }
  }
  if (route && route[FACILITY_REDIRECT]) {
    const nextUrl = `${location.origin}/f/${store.selectCurrentFacilityId()}${path}`

    return next({
      type: URL_ACTION,
      payload: {
        ...payload,
        url: nextUrl,
      }
    })
  }
  return next(action)
})

routeBundle.selectRouteInfo = createSelector(
  'selectRouteMatcher',
  'selectPathname',
  (routeMatcher, pathname) => {
    const routeInfo = routeMatcher(pathname)
    if (!routeInfo.pattern || !routeInfo.pattern.startsWith(FACILITY_ROUTE_PREFIX)) {
      return routeInfo
    }
    const { pattern } = routeInfo

    const [, id = null] = pathname.match(FACILITY_SEGMENT_PATTERN) ?? []
    const routePattern = pattern.replace(FACILITY_ROUTE_PREFIX, '')

    return {
      ...routeInfo,
      params: { ...routeInfo.params, facility: id },
      pattern: routePattern,
      url: pathname,
    }
  }
)

export default routeBundle
