import { last, omit } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import config from '~/src/App/config'
import safeStorage from '~/src/Lib/safeStorage'
import { EMPTY_OBJECT, getDateTime } from '~/src/Lib/Utils'
import {
  firmwareVersionCompareFn,
  firmwareVersionIsGreaterThan,
} from '~/src/Setup/Controllers/utils'

const storage = safeStorage()

const omitFields = ['id', 'active', 'createdOn', 'modifiedOn', 'firmwareVersion', 'createdBy', 'modifiedBy', 'model']

const irrigationControllerMetaBundle = createAsyncResourceBundle({
  name: 'irrigationControllerMeta',
  getPromise: ({ apiFetch }) => apiFetch('/controllerMetadata/', { pagination: 0 }),
})

export default {
  ...irrigationControllerMetaBundle,
  selectIrrigationControllerMetaByModel: createSelector(
    'selectIrrigationControllerMeta',
    irrigationControllerMeta => {
      if (!irrigationControllerMeta?.length) return EMPTY_OBJECT
      return irrigationControllerMeta.reduce((mbm, meta) => ({
        ...mbm,
        [meta.model]: {
          ...mbm[meta.model],
          [meta.firmwareVersion]: omit(omitFields, meta),
        },
      }), {})
    },
  ),
  selectIrrigationModelSupportedFirmwareRange: createSelector(
    'selectIrrigationControllerMetaByModel',
    metaByModel => {
      const today = getDateTime('now').toISODate()
      return Object.fromEntries(
        Object.entries(metaByModel).map(([id, metaByFirmware]) => {
          const firmwareVersions = Object.entries(metaByFirmware)
            .filter(([, { beta, overallSupportedUntil }]) => (
              !beta && (!overallSupportedUntil || overallSupportedUntil > today)
            ))
            .map(([version]) => version)
          firmwareVersions.sort(firmwareVersionCompareFn)
          return [id, [firmwareVersions[0], last(firmwareVersions)]]
        })
      )
    }
  ),
  selectIrrigationControllerFirmwareStatus: createSelector(
    'selectIrrigationControllerMetaByModel',
    'selectIrrigationControllers',
    'selectIrrigationModelSupportedFirmwareRange',
    (metaByModel, irrigationControllers, supportedFirmwareRange) => {
      if (storage.controllerFirmwareUnsupported === 'true' && config.ENVIRONMENT !== 'production') {
        return Object.fromEntries(Object.entries(irrigationControllers).map(([id]) => [id, { supported: false }]))
      }
      if (!Object.keys(metaByModel).length) return {}
      const today = getDateTime('now').toISODate()
      return Object.fromEntries(
        Object.entries(irrigationControllers).map(([id, { firmwareVersion, model }]) => {
          const [oldestSupportedVersion = '', targetFirmwareVersion = ''] = supportedFirmwareRange[model] ?? []
          const firmwareMeta = metaByModel[model]?.[firmwareVersion]
          if (!firmwareMeta) {
            return [id, {
              beta: firmwareVersion ? firmwareVersionIsGreaterThan(firmwareVersion, targetFirmwareVersion) : false,
              controller: Number(id),
              firmwareVersion,
              model,
              oldestSupportedVersion,
              supported: firmwareVersion ? firmwareVersionIsGreaterThan(firmwareVersion, oldestSupportedVersion) : true,
              targetFirmwareVersion,
            }]
          }
          const {
            beta,
            deprecationStart,
            overallSupportedUntil,
          } = firmwareMeta
          const deprecated = deprecationStart ? deprecationStart <= today : false
          const supported = overallSupportedUntil ? overallSupportedUntil > today : true
          return [id, {
            beta,
            controller: Number(id),
            deprecated,
            deprecationStart,
            firmwareVersion,
            model,
            oldestSupportedVersion,
            overallSupportedUntil,
            supported,
            targetFirmwareVersion,
          }]
        })
      )
    },
  ),
  reactFetchIrrigationControllerMeta: createSelector(
    'selectAuth',
    'selectIrrigationControllerMetaIsLoading',
    'selectIrrigationControllerMetaShouldUpdate',
    ({ authenticated }, isLoading, shouldUpdate) => {
      if (authenticated && shouldUpdate && !isLoading) {
        return { actionCreator: 'doFetchIrrigationControllerMeta' }
      }
      return null
    },
  ),
}
