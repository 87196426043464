import config from '~/src/App/config'
import safeStorage from '~/src/Lib/safeStorage'

import { PUBLIC_ROUTES } from '../Routes/constants'
import { isPublicRoute } from '../Routes/utils'
import getGlobal from './getGlobal'
import createLogger from './Logging'
import { EMPTY_ARRAY, EMPTY_OBJECT } from './Utils'

const localStorage = safeStorage('local')

const logger = createLogger('Lib/Auth')

const DEFAULT_TOKENS = {
  access: null,
  facilities: EMPTY_ARRAY,
  refresh: null,
  user: null
}

export const AUTH_URL = '/token/'
export const AUTH_REFRESH_URL = `${config.API_URL}${AUTH_URL}refresh/`

export const getTokens = () => {
  const storedTokens = localStorage.getItem(config.AUTH_TOKEN_KEY)
  if (storedTokens) {
    try {
      return JSON.parse(storedTokens)
    } catch (err) {
      logger.error('Error parsing auth token JSON')
      localStorage.removeItem(config.AUTH_TOKEN_KEY)
    }
  }
  return DEFAULT_TOKENS
}

const deleteCookie = () => {
  const bareDomain = new URL(AUTH_REFRESH_URL)?.host?.match(/\w+\.\w+$/)?.[0]

  const cookie = ([
    'aroya-auth=',
    `Domain=${bareDomain}`,
    'Path=/',
    'Max-Age=-999999'
  ]).join('; ')

  document.cookie = cookie
}

export const setTokens = payload => {
  if (payload) {
    localStorage.setItem(config.AUTH_TOKEN_KEY, JSON.stringify(payload))
  }
  return payload
}

export const clearTokens = () => {
  localStorage.removeItem(config.AUTH_TOKEN_KEY)
  deleteCookie()
}

// {"code":"token_not_valid"}
export const refreshTokens = async store => {
  const oldTokens = getTokens()
  const { pathname } = getGlobal().location ?? EMPTY_OBJECT

  if (isPublicRoute(pathname)) {
    logger('Skipping token refresh for public route:', pathname)
    return oldTokens
  }

  const response = await fetch(AUTH_REFRESH_URL, {
    body: JSON.stringify({ refresh: oldTokens.refresh }),
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    mode: 'cors',
  })

  let updatedTokens
  let responseData
  try {
    responseData = await response.json()
    if (response.status !== 200) {
      updatedTokens = DEFAULT_TOKENS
      throw new Error(`Token refresh failed: ${response.status} ${JSON.stringify(responseData)}`)
    } else {
      updatedTokens = responseData
    }
  } catch (err) {
    logger.error(err.toString())
    clearTokens()
    if (store) {
      store.doAuthReset()
      store.doUpdateUrl(PUBLIC_ROUTES.LOGIN)
    } else {
      location.replace(PUBLIC_ROUTES.LOGIN)
    }
    return false
  }

  // deepcode ignore UncomaparableValues: updatedTokens is sometimes set to DEFAULT_TOKENS
  if (updatedTokens !== DEFAULT_TOKENS) {
    const { access } = updatedTokens
    const currentMembership = (store ? store.selectCurrentMembership() : null) ?? EMPTY_OBJECT
    if (store && access?.length) {
      if (currentMembership.facility) {
        store.doConnectSocket(currentMembership, access)
      } else {
        console.warn('unable to connect websocket due to missing currentMembership')
        if (!store.selectMeIsLoading()) {
          store.doMarkMeAsOutdated()
        }
      }
    } else {
      logger.warn('unable to refresh websocket when refreshing token:', { access, currentMembership, store })
    }
  } else {
    logger.warn('Invalid refresh token, redirecting to login page.', {
      responseData,
      status: response.status,
      statusText: response.statusText,
    })
    clearTokens()
    location.replace(PUBLIC_ROUTES.LOGIN)
  }

  return setTokens({ ...oldTokens, ...updatedTokens })
}
getGlobal().refreshTokens = refreshTokens
